<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="关键字ID" v-bind="formItemLayout">
        <a-input v-model="form.id" v-decorator="['id']" disabled>
        </a-input>
      </a-form-model-item>
      <a-form-model-item
          label="分类"
          prop="cateId"
          v-bind="formItemLayout"
      >
        <a-select :options="cateList" v-model="form.cateId" v-decorator="['cateId']"></a-select>
      </a-form-model-item>
      <a-form-model-item
          label="状态"
          prop="status"
          v-bind="formItemLayout"
      >
        <!--        <a-input v-model="form.group" v-decorator="['group']" placeholder="标签分组">-->
        <!--        </a-input>-->
        <a-radio-group v-decorator="['status',{initialValue: 1}]" v-model="form.status">
          <a-radio :value="0">待审核</a-radio>
          <a-radio :value="1">已审核</a-radio>
          <a-radio :value="-1">未通过</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="关键字" v-bind="formItemLayout" required prop="word">
        <a-input v-model="form.word" v-decorator="['word']" placeholder="关键字，最多20个字" :max-length="20">
        </a-input>
      </a-form-model-item>
      <a-form-model-item
          label="类型"
          prop="type"
          v-bind="formItemLayout"
      >
        <a-radio-group v-decorator="['type',{initialValue: 1}]" v-model="form.type">
          <a-radio :value="1">平替词</a-radio>
          <a-radio :value="2">代工厂</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="平替词/代工厂" v-bind="formItemLayout" required prop="replaceWord">
        <a-input v-model="form.replaceWord" v-decorator="['replaceWord']" placeholder="平替词/代工厂，最多20个字" :max-length="20">
        </a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: "ModifyConfigTaskModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 16},
      },
      mode: 'new',
      title: "",
      cateList: [],
      visible: false,
      confirmLoading: false,
      form: {},
      rules: {
        industryName: [
          {
            required: true,
            message: "行业名称不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },

  mounted() {
    this.$postJson('/category/cateList', {pageSize: 100, type: 4}).then(res => {
      this.cateList = ((res.data && res.data.records) || [])
          .map(v => {
            return {
              value: v.cateId,
              label: v.cateName
            }
          })
    })
  },
  methods: {
    showModal(record, mode) {
      this.mode = mode || 'new'
      this.visible = true;
      if (this.mode === 'update') {
        this.title = "修改：" + record.id;
        this.form = {
          ...record,
        };
      } else {
        this.title = "新增"
        this.form = {
          status: 1
        }
      }
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          this.confirmLoading = true;
          this.$postJson("cheaper/saveWord", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("保存成功");
            this.$emit("success");
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
